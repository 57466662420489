<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input class="filter-item" v-model="listQuery.keyword" placeholder="请输入订单号/手机号/姓名" style="width: 260px;" clearable/>

      <el-select class="filter-item" v-model="listQuery.coupon_package_id" filterable placeholder="请选择优惠券套餐id" style="width: 200px" clearable>
        <el-option v-for="item in couponPackageList" :key="item.id" :label="item.id" :value="item.id">
          <div style="display: flex;align-items:center;text-align:center;">
            <span>{{ item.id }}</span>

            <span>---</span>
            <span v-if="item.coupon.coupon_type === 1">无门槛 </span>
            <span v-else-if="item.coupon.coupon_type === 2">满{{item.coupon.minimum}}可用</span>
            <span v-else-if="item.coupon.coupon_type === 3">商品优惠</span>

            <span>---</span>
            <span v-if="item.coupon.coupon_scope === 1">全平台</span>
            <span v-else>{{item.coupon.shop.shop_name}}</span>

            <span>---</span>
            <span v-if="item.coupon.coupon_type === 3">
                  <template v-if="item.coupon.discount_mode === 1">
                      商品优惠{{item.coupon.discount_num}}折
                    </template>
                    <template v-else-if="item.coupon.discount_mode === 2">
                      商品立减{{item.coupon.discount}}元
                    </template>
                </span>
            <span v-else>优惠立减{{ item.coupon.discount }}元</span>
          </div>
        </el-option>
      </el-select>

      <el-date-picker v-model="dateArr" type="datetimerange" align="right" value-format="yyyy-MM-dd HH:mm:ss" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :default-time="['00:00:00', '23:59:59']" class="filter-item" style="display: inline-flex;" clearable></el-date-picker>

      <el-button type="primary" class="filter-item" @click="handleFilter">搜索</el-button>
    </div>
    <div class="total">
      <div class="total-num">
        <span>{{total_pay_count}}</span>
        <span>销售数量合计</span>
      </div>
      <div class="total-price">
        <span>{{total_pay_sum}}</span>
        <span>销售金额合计(元)</span>
      </div>
    </div>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column align="center" label="ID" width="80">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="订单号" min-width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_no }}
        </template>
      </el-table-column>
      <el-table-column label="优惠券类型" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon_package_snap.coupon.coupon_type === 1">无门槛</span>
          <span v-else-if="scope.row.coupon_package_snap.coupon.coupon_type === 2">满{{scope.row.coupon_package_snap.coupon.minimum}}可用</span>
          <span v-else-if="scope.row.coupon_package_snap.coupon.coupon_type === 3">商品优惠</span>
        </template>
      </el-table-column>
      <el-table-column label="优惠券使用范围" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon_package_snap.coupon.coupon_scope === 1">全平台</span>
          <span v-else>{{scope.row.coupon_package_snap.coupon.shop.shop_name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="优惠金额" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon_package_snap.coupon.coupon_type === 3">
            <template v-if="scope.row.coupon_package_snap.coupon.discount_mode === 1">
              商品优惠{{scope.row.coupon_package_snap.coupon.discount_num}}折
            </template>
            <template v-else-if="scope.row.coupon_package_snap.coupon.discount_mode === 2">
              商品立减{{scope.row.coupon_package_snap.coupon.discount}}元
            </template>
          </span>
          <span v-else>{{ scope.row.coupon_package_snap.coupon.discount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户" min-width="110" align="center">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction:column;align-items:center;text-align:center;">
            <img :src="scope.row.user.avatar" class="user-avatar">
            <span>{{ scope.row.user.nickname }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="姓名" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.user.name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="手机号码" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.user.phone }}</div>
        </template>
      </el-table-column>
      <el-table-column label="支付金额" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.pay_money }}
        </template>
      </el-table-column>
      <el-table-column label="支付状态" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.pay_status == 0">未支付</span>
          <span v-else-if="scope.row.pay_status == 1">已支付</span>
          <span v-else-if="scope.row.pay_status == 2">已取消</span>
        </template>
      </el-table-column>
      <el-table-column label="支付时间" min-width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.pay_time }}
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
  import request from "@/utils/request";
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          school_id: '',
          keyword: '',
          coupon_package_id: '',
          start_time: '',
          end_time: '',
        },
        dateArr: null,
        total_pay_count: 0,
        total_pay_sum: 0,
        couponPackageList: [],
      };
    },
    created() {
      this.getList();
      this.getCouponPackageList();
    },
    computed: {
      ...mapGetters(["schools", "school_id"])
    },
    watch: {
      school_id(newVal, oldVal) {
        this.listQuery.school_id = newVal;
        this.getList();
        this.getCouponPackageList();
      },
      dateArr(newVal, oldVal) {
        if (newVal) {
          this.listQuery.start_time = newVal[0]
          this.listQuery.end_time = newVal[1]
        } else {
          this.listQuery.start_time = ''
          this.listQuery.end_time = ''
        }
      }
    },
    methods: {
      getList() {
        this.listQuery.school_id = this.school_id;
        this.listLoading = true;
        request({
          url: "/api/backend/couponOrder/list",
          method: "get",
          params: this.listQuery
        }).then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
          this.total_pay_count = response.data.total_pay_count;
          this.total_pay_sum = response.data.total_pay_sum;
          this.listLoading = false;
        });
      },
      getCouponPackageList() {
        if (!this.school_id) {
          this.couponPackageList = [];
          return;
        }
        request({
          url: "/api/backend/couponPackage/list",
          method: "get",
          params: {
            page: 0,
            limit: 1000000,
            school_id: this.school_id
          }
        }).then(response => {
          this.couponPackageList = response.data.data;
        });
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleSizeChange(val) {
        this.listQuery.limit = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.listQuery.page = val;
        this.getList();
      },
    }
  };
</script>

<style rel="stylesheet/scss" lang="scss">
  .custom-inline-form {
    margin-bottom: 10px;
    .el-row {
      padding: 4px 0;
      font-weight: bold;
    }
    .el-form-item {
      margin-bottom: 4px !important;
    }
    .el-form-item__label {
      line-height: 20px !important;
    }
    .el-form-item__content {
      line-height: 20px !important;
    }
  }
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 6px;
  }
  .total {
    width: 100%;
    height: 120px;
    border: 1px solid #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin: 10px auto;
    .total-num,
    .total-price {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      font-size: 14px;
      span {
        margin-bottom: 10px;
      }
    }
    .total-num {
      border-right: 1px solid #f0f0f0;
    }
  }
</style>
